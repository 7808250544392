

.sidebar{
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    background: #202122;
    width: 12rem;
    position: fixed;
    top: 0;
    height: 100%;
    transition: all 0.15s;
    color: #e2e2e2;
    z-index: 50;
    overflow: auto;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    font-weight: 300;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em;
    color: #e2e2e2;
    background-color: #202122;
    position: sticky;
    top: 0;

    

}

.appName{
    font-size: 1.75em;
   
}


.menuIcon {
    font-size: 1.75em;
    margin-left: 2em;
    color: #dbdbdb;
    transition: all 0.3s;

    
}

.menuIcon :hover{
    font-size: larger;
    transition: all 0.05s;
    
}

.row {
    display: flex;
    padding: 1.5em;
    align-items: center;
    gap: 1em;
    background: #202122;
    overflow: hidden;
    color: #dbdbdb;
    
    
}



.sidebar #active{
    /* background-color: #ffffff15; */
    /* border-left: 0.3em solid #c8c8c8; */
    border-radius: 1.75em;
    cursor: pointer;
    transition: all 0.1s;
}

.sidebar #active.row .icon{
    color: #b7e5ff;
}

.sidebar #active:hover{
    cursor: pointer;

}


.sidebar #idle:hover{
    /* background-color: #ffffff1a; */
    cursor: pointer;
    border-radius: 1.5em;
    
    
    /* border: 1px;
    transition: all 0.2s; */

}


.row:hover{
    cursor: pointer;
    background-color: #ffffff0e;
    /* color: #cdecff; */
    /* color: #d0ebff; */
 
}

.row:hover .icon {
    color: #b7e9ff;
}



.title{
    font-size: 1.3rem;
    transition: all 0.1s
}



/* .icon {
    font-size: 1.4rem;
    color: palegreen;
} */