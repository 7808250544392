
.project-sidebar{
    background: #f0f0f0;
    /* width: 12rem; */
    position: fixed;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    border-right: 0.05em solid #00000008;
    z-index: 5;
    will-change: width;
}

.project-sidebar2{
    background: #f0f0f0;
    /* width: 12rem; */
    position: absolute;
    height: 80vh;
    overflow-y: scroll;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    border-right: 0.05em solid #00000008;
    
}