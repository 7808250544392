
.selected-proj-sidebar {
    background: #f0f0f0;
    position: absolute;
    height: 100vh;
    overflow-y: scroll;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    border-right: 0.05em solid #00000008;
    z-index: 5;
}


.header-container {
    display: flex;
    place-content: center;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 1.5em;
    background-color: #f0f0f0;
    position: sticky;
    top: 0

}

.selected-btn {
    font-size: 1.4em;
}

.select-top-container {
    display: flex;
    place-content: center;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 1.5em;
    /* position: sticky; */
    /* top: 0 */
}