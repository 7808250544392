.App {
  color: azure;
}

body{
  margin: 0;
  padding: 0;
  
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}
