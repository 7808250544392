
.project-sidebar{
    background: #f0f0f0;
    /* width: 12rem; */
    position: fixed;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    border-right: 0.05em solid #00000008;
    will-change: width;
}

.project-sidebar2{
    background: #f0f0f0;
    /* width: 12rem; */
    position: absolute;
    height: 80vh;
    overflow-y: scroll;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    border-right: 0.05em solid #00000008;
    
}

.all-top {
    background-color: inherit;
    position: sticky;
    top: 0;
    padding-bottom: 0.5em;
    margin-top: 2em;

}






.proj-nav-search-bar {  
    padding: 0.275em;
    margin-bottom: 0.5em;
    margin-left: 0.475em;

    
    width: 85%;
    border-radius: 0.375rem; 

    color: #505050;
    font-size: 1.05em;
    background: #f0f0f0; 
    
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

.proj-nav-search-bar:focus{
    outline: none;
    background: #eaeaea; 
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}

.proj-nav-search-bar:hover{
    background: #e6e6e6; 
}


.proj-nav-search-bar::placeholder{
    color: #505050; 
}

.AddProj {
    font-size: 1.75em;
    background: #e5e5e5; 
     border-radius: 0.3em;
    /* border: 1px ; */
    margin-bottom: 0em;
    margin-left: 0.475em;
    margin-bottom: 0.4em;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    
    
 } 

 .AddProj :hover{
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    transition: all 0.1s;
 }




.project-row {
    display: flex;
    padding: 0.8em;
    align-items: center;
    text-align: center;
    background: transparent;
    color: #505050;
    width: 100%;
    /* margin-left: 0.3em; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    
}


.project-row:hover{
    background-color: #00000009;
    cursor: pointer;
    border-radius: 1rem;
    border: 1px;
    /* transition: all 0.1s;
    font-size: 1.25em; */

}

.row:hover{
    cursor: pointer;

}

.project-sidebar #ProjOn{
    background-color: #0000001a;
    border-radius: 1rem;
    border: 1px;
    cursor: pointer;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);


}



.project-title {
    margin-left: 0.2em;
    /* font-size: 1.05em; */
}